<template>
	<div class="font-list-item flex flex-jc-sb flex-ai-c" style="padding: 5px 10px;" @click="openDetail">
		<i v-if="index < 3" class="fa fa-bar-chart m-r-5" :style="iconColor" style="font-size: 12px;"></i>
		<i v-if="index >= 3" class="fa fa-caret-right m-r-5" style="color: #333333;font-size: 12px;"></i>
        <h4 class="text-ellipsis flex1 font-15">{{item.title}}</h4>
		<span class="date text-align-r grey m-l-10 font-13">{{item.created}}</span>
	</div>
</template>

<script>
	export default {
		props:{
			item:{
				type:Object
			},
			index:{
				type:Number
			}
		},
        data() {
            return {
                
            }
        },
		computed:{
			iconColor(){
				if(this.index == 0){
					return 'color:#FF0033';
				}else if(this.index == 1){
					return 'color:#FF3333';
				}else if(this.index == 2){
					return 'color:#FF6633';
				}
				return '';
			}
		},
        methods:{
            openDetail(){
                if(!this.item.link){
                    this.$router.push({
                        path:'/article/details',
                        query:{
                            id:this.item.id
                        }
                    })
                }else{
                    window.location.href = this.item.link
                }
            }
        }
	}
</script>

<style>
	.font-list-item{
		padding: 0;
	}
	.font-list-item h4{
	}
	.font-list-item .date{
	}
</style>
