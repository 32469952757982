<template>
	<div class="content-item padding-10" style="background-color: #FCF0D7;">
		<van-swipe class="my-swipe" :autoplay="6000" indicator-color="white">
			<van-swipe-item v-for="(item,index) in banner" :key="index" @click="openBanner(item.link)">
				<img :src="item.thumb" class="banner-img">
			</van-swipe-item>
		</van-swipe>
		
		<!-- <div class="title-item m-10">
			<div class="font-20 font-b">
				<p>兴义市百春幼儿园省级示范幼儿园</p>
				<p class="m-t-5">复查暨升类评估工作</p>
			</div>
			<div class="font-15 grey m-t-10">
				<p><i class="fa fa-clock-o m-r-10"></i>2021年6月21-23日</p>
				<p class="m-t-5"><i class="fa fa-map-marker m-r-10"></i>兴义市百春幼儿园</p>
			</div>
		</div> -->
        
        <!-- 公告 -->
        <div class="m-t-10">
            <van-notice-bar
                scrollable 
                left-icon="volume-o"
                :text="title + '欢迎您'"
            />
        </div>
		
        <div class="bc-white padding-b-10 padding-t-20 m-t-10 br-10 box-shadow">
            <van-grid :gutter="10" :column-num="navNum" :border="false">
                <van-grid-item v-for="(item,index) in tabBars" :key="index" :icon="item.thumb" :text="item.title" :url="item.url"/>
            </van-grid>
        </div>
       
        <div class="m-t-10 padding-l-5 padding-r-5 padding-b-10 bc-white br-10 box-shadow">
            <div class="text-align font-20 padding-10">
                <i class="fa fa-music m-r-10" style="color: #FF0066;"></i>{{title}}园歌
            </div>
            <div class="">
                <aplayer :music="audio[0]" :list="[]" :showLrc="true"></aplayer>
            </div>
        </div>
        
        <div class="m-t-10 bc-white box-shadow" style="border-radius: 10px 10px 0 0;">
            <div class="text-align font-20 padding-10">
                <i class="fa fa-video-camera m-r-10" style="color: #FF0066;"></i>{{title}}宣传片
            </div>
            <div class="">
                <video :id="'V'+videoData.vid" class="video-js test_two_box" style="height: 210px !important;">
                    <source :src="videoData.fhash" type="application/x-mpegURL" />
                </video>
            </div>
        </div>

        <!-- <div class="m-t-10 bc-white box-shadow" style="border-radius: 10px 10px 0 0;">
            <div class="text-align font-20 padding-10">
                <i class="fa fa-video-camera m-r-10" style="color: #FF0066;"></i>百春幼儿园活动剪影
            </div>
            <div class="">
                <video :id="'V'+videoDataone.vid" class="video-js test_two_box" style="height: 210px !important;">
                    <source :src="videoDataone.fhash" type="application/x-mpegURL" />
                </video>
            </div>
        </div> -->
       
        
        <!-- 文字列表 -->
        <div v-if="fontList.length > 0" class="bc-white m-t-10 padding-t-10 padding-b-10 br-10 box-shadow">
            <div class="padding-0-20">
                <divider><div style="font-size: 18px;">最新微信推送</div></divider>
            </div>
            <div v-for="(item,index) in fontList" :key="index">
                <font-list :item="item" :index="index"></font-list>
            </div>
        </div>
        
        <h-40></h-40>
        
		<common-footer divider></common-footer>
	</div>
</template>

<script>
	import aplayer from "vue-aplayer";
    import fontList from '../components/list/font-list.vue'
	
	export default {
        
		name:'Index',
		components:{
			aplayer,
            fontList
		}, 
		data() {
			return {
                title:'',
				banner:[],
                tabBars:[],
				videoStatus:false,
				audio: [
					{
						title: '兴义市百春幼儿园园歌',
						artist: '全体师生',
						src: '/music.wav',
						pic: '/images/music.jpg',
						lrc: '[00:00.00]'
					}
				],
                fontList:[],
                videoData:{
                    vid:'vierow',
                    fhash:'https://cdn1.naoketang.com/abaichun1.m3u8'
                },
                // videoDataone:{
                //     vid:'vierowa',
                //     fhash:'https://cdn1.naoketang.com/abaichun.m3u8'
                // },
                videoObj:'' ,// 视频实例对象，主要用户销毁使用abaichun1.m3u8
                tianqi:'',
                weixin:[
                    {
                        name:'服务号',
                        codeUrl:''
                    }
                ]
			}
		},
		mounted() {
			this.getBanner()
			this.getTabBars()
            this.getFontList()
            this.getSchoolInfo()
            
            this.getVideo()
            // this.getVideoa()
		},
        computed:{
            navNum(){
                
                if(this.tabBars.length >= 10){
                    return 5
                }else{
                    return 4
                }
            },
            iconSize(){
                if(this.tabBars.length >= 10){
                    return 40
                }else{
                    return 60
                }
            }
        },
		methods: {
			getBanner(){
				this.$api.index.getBanner().then((res) => {
                    this.banner = res.data
				})
			},
            getFontList(){
                this.$api.article.getCategoriesArticleList('wei-xin-tui-song').then((res) => {                    
                    let fontData = []
                    for(let i=0;i<8;i++){
                        fontData.push(res.data[i])
                    }
                    this.fontList = fontData
                })
            },

			getWaterfall(){
				this.$api.index.getWaterfall().then((res) => {
                    let len = res.data.length                    
                    if(len == 0) return false                    
                    let arr = []
                    if(len > 14){
                        for(let i=0; i<14; i++){
                            arr.push(res.data[i])
                        }
                    }
                    if(len <= 14){
                        for(let i=0; i<len; i++){
                            arr.push(res.data[i])
                        }
                    }
                    this.waterfallData = arr
				})
			},
			getTabBars(){
				this.$api.index.getTabBars().then((res) => {
					this.tabBars = res.data                    
				})
			},
            openBanner(link){
                if(link) window.location.href = link
            },
            getSchoolInfo(){
                let schoolInfo = this.$storage.getItem('schoolInfo')
                if(!schoolInfo){
                    this.$api.common.getSchoolInfo().then((res) => {
                        let data = res.data
                        if(data != ''){
                            this.$storage.setItem('schoolInfo',data)
                            this.title = data.title
                            this.tianqi = data.thumb
                            document.title = this.title
                        }
                    })
                }else{
                    this.title = schoolInfo.title
                    this.tianqi = schoolInfo.thumb
                    document.title = this.title
                }
            },
            openVideo(vid){
                window.location.href = '/video/details?vid='+vid
            },
            getVideo(){
                // this.$api.video.getVideoById().then((res) => {
                    // this.videoData = res.data
                    // if(res.data != ''){
                    //     setTimeout(() => {
                    //         this.videoEvent(this.videoData.vid)
                    //     },200)
                    // }
                // })
                this.videoEvent(this.videoData.vid)
            },
            // getVideoa(){
            //     // this.$api.video.getVideoById().then((res) => {
            //         // this.videoData = res.data
            //         // if(res.data != ''){
            //         //     setTimeout(() => {
            //         //         this.videoEvent(this.videoData.vid)
            //         //     },200)
            //         // }
            //     // })
            //     this.videoEvent(this.videoDataone.vid)
            // },
            videoEvent(id){
                this.videoObj = this.$video('V'+id, {
                    //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                    controls: true,
                    // poster: '/videos/cover.png', //封面
                    //自动播放属性,muted:静音播放
                    // autoplay: "muted",
                    //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                    // preload: "auto",
                    //设置视频播放器的显示宽度（以像素为单位）
                    width: "300",
                    //设置视频播放器的显示高度（以像素为单位）
                    height: "320"
                });
            }
		},
        beforeDestroy() {
            if(this.videoObj) this.videoObj.dispose()
        }
	}
</script>


<style>
    .van-swipe-item,.van-swipe,.van-swipe__track{
        border-radius: 15px;
    }
    .banner-img{
        width: 100%;
        height: 100%;
        border-radius: 15px;
        display: block;
    }
    /* 公告样式 */
    .van-notice-bar{
        background-color: #75423B;
        color: #FCF0D7;
        border-radius: 10px;
    }
    .title-ite p{
        margin: 0;
    }

    .van-grid-item .van-grid-item__content{
        /* background-color: #f5f8fa; */
        padding: 5px 8px;
    }

    .van-grid-item__icon+.van-grid-item__text{
        margin-top: 0;
    }
    
    .van-icon__image{
        width: 1.4em;
        height: 1.4em;
    }

    .wx_code{
        width: 200px;
        height: 200px;
    }
    
    .vjs-poster{
        background-size: auto;
    }

    .vjs-paused .vjs-big-play-button,
    .vjs-paused.vjs-has-started .vjs-big-play-button {
        display: block;
    } 
    
    .video-js{
        height: 191px;
    }
    
    .video-js .vjs-big-play-button{
        font-size: 2.5em;
        line-height: 2.3em;
        height: 2.5em;
        width: 2.5em;
        -webkit-border-radius: 2.5em;
        -moz-border-radius: 2.5em;
        border-radius: 2.5em;
        background-color: #73859f;
        background-color: rgba(115,133,159,.5);
        border-width: 0.15em;
        position: absolute;
        top: 33%;
        left: 40%;
    }
    /* 中间的播放箭头 */
    .vjs-big-play-button .vjs-icon-placeholder {
        font-size: 1.63em;
    }
    /* 加载圆圈 */
    .vjs-loading-spinner {
        font-size: 2.5em;
        width: 2em;
        height: 2em;
        border-radius: 1em;
        margin-top: -1em;
        margin-left: -1.5em;
    }
	
</style>